import Snowfall from 'react-snowfall';
import xmas from './as-christmas-24-export.png';
function App() {
  return (
    <div className="app">
      <Snowfall
        color="white"
        style={{ background: '#ff0000' }}
        snowflakeCount={350}
      />
      <a
        href="https://calendar.app.google/mbW18AyTohZMvTvE9"
        target="_blank"
        rel="noreferrer noopener"
        className="inner"
      >
        <img src={xmas} alt="Alright Christmas Illo 2024" />
      </a>
    </div>
  );
}

export default App;
